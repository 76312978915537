/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/prop-types */
import { PureComponent } from 'react';

/** @namespace Efex/MorhaneSeo/Component/CanonicalPagination/Component */
export class CanonicalPaginationComponent extends PureComponent {
    renderCanonicalPagination() {
        const { totalPages = 0, currentPage: currentPageNumber } = this.props;
        const { href } = window.location;
        const prevUrl = new URL(href);
        const nextUrl = new URL(href);
        const { searchParams: prevSearchParams } = prevUrl;
        const { searchParams: nextSearchParams } = nextUrl;

        if (totalPages === 1) {
            return null;
        }

        if (currentPageNumber === 0) {
            document.head.querySelectorAll('link[rel="prev"]').remove();

            if (href.includes('?')) {
                return (
                    <link rel="next" href={ `${decodeURIComponent(href)}&page=2` } />
                );
            }

            return (
                <link rel="next" href={ `${decodeURIComponent(href)}?page=2` } />
            );
        // eslint-disable-next-line no-else-return
        } else if (currentPageNumber < Number(totalPages)) {
            prevSearchParams.set('page', currentPageNumber - 1);
            const updatedPrevUrl = `${prevUrl.origin}${prevUrl.pathname}?${prevSearchParams.toString()}`;
            nextSearchParams.set('page', currentPageNumber + 1);
            const updatedNextUrl = `${nextUrl.origin}${nextUrl.pathname}?${nextSearchParams.toString()}`;

            return (
                <>
                    { currentPageNumber - 1 > 0 && <link rel="prev" href={ decodeURIComponent(updatedPrevUrl) } /> }
                    <link rel="next" href={ decodeURIComponent(updatedNextUrl) } />
                </>
            );
        } else if (currentPageNumber === Number(totalPages)) {
            prevSearchParams.set('page', currentPageNumber - 1);
            const updatedUrl = `${prevUrl.origin}${prevUrl.pathname}?${prevSearchParams.toString()}`;

            return (
                <link rel="prev" href={ decodeURIComponent(updatedUrl) } />
            );
        }

        return null;
    }

    render() {
        return this.renderCanonicalPagination();
    }
}

export default CanonicalPaginationComponent;
