/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';

import { addHreflangTags } from '../../util/AddHreflangTags.js';
import HreflangUrlsQuery from '../query/HreflangUrls.query';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurations: state.SeoConfigurationReducer.seoConfigurations,
        productList: state.ProductListReducer.pages,
        totalPages: state.ProductListReducer.totalPages,
        configs: state.ConfigReducer,
        urlRewrite: state.UrlRewritesReducer.urlRewrite,
    };
};

export class CategoryPageContainerPlugin {
    constructExtended = (args, callback, instance) => {
        // eslint-disable-next-line no-param-reassign
        instance.state = {
            hreflangUrls: [],
        };
    };

    containerProps = (args, callback, instance) => {
        const { productList, configs, totalPages } = instance.props;

        return {
            ...callback(...args),
            productList,
            configs,
            totalPages,
        };
    };

    componentDidMount = (args, callback, _instance) => {
        const { categoryIds, urlRewrite: { type } } = _instance.props;

        if (type !== 'AMASTY_XLANDING_PAGE') {
            fetchQuery(HreflangUrlsQuery.getHreflangUrls(categoryIds, 'category'))
                .then(
                    (response) => {
                        addHreflangTags(response?.hreflangUrls || []);
                    }
                );
        }
        callback(...args);
    };

    updateBreadcrumbs = (args, callback, instance) => {
        const isUnmatchedCategory = args[0];
        const {
            updateBreadcrumbs,
            category,
            configs: {
                code = '',
            } = {},
            match: {
                url: category_url = '',
            },
            isSearchPage,
        } = instance.props;
        const url_code = window.location.pathname.split('/')[1];
        const url_path = code === url_code ? window.location.pathname : `/${code}${window.location.pathname}`;
        const {
            id = 0,
            url = '',
            name = '',
            breadcrumbs = [],
            is_active = false,
        } = isUnmatchedCategory ? {} : category;

        updateBreadcrumbs({
            id: Number(id),
            url,
            name,
            breadcrumbs,
            is_active,
        });

        if (isSearchPage) {
            updateBreadcrumbs({
                url: url_path,
                name: category_url.split('/')[2],
            });
        }

        instance.setState({ breadcrumbsWereUpdated: true });
    };
}

const {
    componentDidMount,
    constructExtended,
    containerProps,
    updateBreadcrumbs,
} = new CategoryPageContainerPlugin();

export default {
    'Route/CategoryPage/Container': {
        'member-function': {
            componentDidMount,
            __construct: constructExtended,
            containerProps,
            updateBreadcrumbs,
        },
    },
    'Route/CategoryPage/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps,
            },
        ],
    },
};
