/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/prop-types */
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import MetaDispatcher from 'Store/Meta/Meta.dispatcher';
import history from 'Util/History';

import CanonicalPagination from './CanonicalPagination.component';

/** @namespace Efex/MorhaneSeo/Component/CanonicalPagination/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    category: state.CategoryReducer.category,
});

/** @namespace Efex/MorhaneSeo/Component/CanonicalPagination/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMeta: (category) => MetaDispatcher.updateWithCategory(category, dispatch),
});

/** @namespace Efex/MorhaneSeo/Component/CanonicalPagination/Container */
export class CanonicalPaginationContainer extends PureComponent {
    __construct() {
        this.state = {
            currentPage: 1,
            urlSearch: '',
        };
    }

    componentDidMount() {
        const {
            location: {
                search,
            },
        } = history;
        const { category = {}, updateMeta } = this.props;
        const urlParams = new URLSearchParams(window.location.search);
        const currentPageNumber = Number(urlParams.get('page'));

        if (currentPageNumber === 0) {
            const canonical_url = `${window.location.pathname}`;
            const updatedCategory = { ...category, canonical_url };
            updateMeta(updatedCategory);

            this.setState({
                currentPage: currentPageNumber + 1,
            });
        } else {
            const canonical_url = `${window.location.pathname}?page=${currentPageNumber}`;
            const updatedCategory = { ...category, canonical_url };
            updateMeta(updatedCategory);

            this.setState({
                currentPage: currentPageNumber,
            });
        }

        this.setState({ urlSearch: search });
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            location: {
                search,
            },
        } = history;
        const { currentPage: prevPage, search: prevSearch } = prevState;
        const { category = {}, updateMeta } = this.props;
        const urlParams = new URLSearchParams(search);
        const currentPageNumber = Number(urlParams.get('page'));

        if (prevSearch !== search) {
            this.setState({ urlSearch: search });
        }

        if (prevPage !== currentPageNumber && currentPageNumber !== 0) {
            const canonical_url = `${window.location.pathname}?page=${currentPageNumber}`;
            const updatedCategory = { ...category, canonical_url };
            updateMeta(updatedCategory);

            this.setState({
                currentPage: currentPageNumber,
            });
        }

        if (currentPageNumber === 0) {
            const canonical_url = `${window.location.pathname}`;
            const updatedCategory = { ...category, canonical_url };
            updateMeta(updatedCategory);

            this.setState({
                currentPage: currentPageNumber + 1,
            });
        }
    }

    containerProps() {
        const {
            productList: {
                totalPages = 0,
            } = {},
        } = this.props;

        const { currentPage, urlSearch } = this.state;

        return {
            totalPages,
            currentPage,
            urlSearch,
        };
    }

    render() {
        return (
            <CanonicalPagination
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CanonicalPaginationContainer);
