/* eslint-disable arrow-body-style */
/* eslint-disable comma-dangle */
/* eslint-disable quote-props */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet';

import CanonicalPagination from '../component/CanonicalPagination';
import Opengraph from '../component/Opengraph';
import TwitterCard from '../component/TwitterCard';

export class CategoryPageComponentPlugin {
    getItemListElement = (products) => {
        return products.reduce((result, product, index) => {
            const {
                name = '',
                type_id = '',
                rating_summary = 0,
                review_count = 0,
                stock_status = '',
                image: {
                    url: baseImageUrl = ''
                } = {},
                price_range: {
                    maximum_price: { final_price: maximumFinalPrice = 0 } = {},
                    minimum_price: { final_price: minimumFinalPrice = 0 } = {}
                } = {},
                url
            } = product;
            const availability = stock_status === 'IN_STOCK' ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';
            const share = 20;
            const element = {
                '@type': 'ListItem',
                'position': index + 1,
                'item': {
                    '@type': 'Product',
                    'name': name,
                    'image': [baseImageUrl],
                    'url': `${window.location.origin}${url}`
                }
            };

            if (review_count > 0) {
                element.item.aggregateRating = {
                    '@type': 'AggregateRating',
                    'ratingValue': rating_summary / share,
                    'reviewCount': review_count,
                    'bestRating': 5
                };
            }

            if (type_id !== 'simple') {
                element.item.offers = {
                    '@type': 'AggregateOffer',
                    'priceCurrency': maximumFinalPrice.currency,
                    'lowPrice': minimumFinalPrice.value,
                    'highPrice': maximumFinalPrice.value,
                };
            } else {
                element.item.offers = {
                    '@type': 'Offer',
                    'priceCurrency': maximumFinalPrice.currency,
                    'price': maximumFinalPrice.value,
                    'availability': availability
                };
            }
            result.push(element);

            return result;
        }, []);
    };

    renderCategoryMicroData = (props) => {
        const { productList = {} } = props;
        const [products = []] = Object.values(productList);

        const itemListElement = this.getItemListElement(products);

        const productRichSnippet = {
            '@context': 'https://schema.org',
            '@type': 'ItemList',
            'itemListElement': itemListElement
        };

        return (
            <script type="application/ld+json">
                { JSON.stringify(productRichSnippet) }
            </script>
        );
    };

    renderOpengraph = (props) => {
        return (
            <Opengraph
              type="category"
              entity={ props.category }
            />
        );
    };

    renderTwitterCard = (props) => (
        <TwitterCard
          type="category"
          entity={ props.category }
        />
    );

    renderCanonicalPagination = (props) => {
        return (
            <CanonicalPagination
              productList={ props }
            />
        );
    };

    render = (args, callback, instance) => {
        return (
            <>
                <Helmet>
                    { this.renderCategoryMicroData(instance.props) }
                </Helmet>
                { createPortal({ ...this.renderCanonicalPagination(instance.props) }, document.head) }
                { createPortal({ ...this.renderOpengraph(instance.props) }, document.head) }
                { createPortal({ ...this.renderTwitterCard(instance.props) }, document.head) }
            { callback(...args) }
            </>
        );
    };
}

const {
    render,
} = new CategoryPageComponentPlugin();

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            render,
        },
    },
};
